'use client';

import Layout from "@/components/layout/Layout";
import { MetadataContext } from "@/context/metadata.context";
import { useRouter } from "next/navigation"
import { useContext, useEffect } from "react";

export default function NotFound() {
    const { state } = useContext(MetadataContext);
    const router = useRouter();

    useEffect(() => {
        router.push('/');
    });

    return <>
    <Layout
      headerStyle={1}
      headerCls="viewport-lg"
      headTitle={`Not Found - ${state.location?.name ?? ""}`}
    >
        </Layout>
        </>
}